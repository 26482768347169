import { Link } from 'gatsby';
import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container p-5">
          <div className="row">
            <div className="col-6 col-md-2">
              <h4>CarlosHdz - 2021</h4>
            </div>
            <div className="col-6">
              <h4>Link</h4>
              <div className="d-flex flex-column">
                <Link className="footer-link" to="/">
                  Home
                </Link>
                <Link className="footer-link" to="/contact">
                  About
                </Link>
                <Link className="footer-link" to="/projects">
                  Projects
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
