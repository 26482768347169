import React from 'react';
import Navbar from './Navbar';
import '../styles/global.scss';
import Footer from './Footer';
import { TProps } from '../types/TProps';

const Layout = ({ children }: TProps) => {
  return (
    <div className="layout">
      <Navbar />
      <div className="pt-3">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
